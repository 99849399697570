<template>
    <div>
        <vs-table
            search 
            stripe 
            border 
            description 
            :sst="true" 
            :data="table.data" 
            :max-items="table.length"
            :total="table.total" 
            @search="handleSearch" 
            @change-page="handleChangePage" 
            @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>

            <template v-if="status === 'Handover'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="personals.name">Sales</vs-th>
                <vs-th sort-key="warehouse.name">Warehouse</vs-th>
                <vs-th sort-key="canvas_item_requests.code">Item Request Code</vs-th>
                <vs-th sort-key="canvas_handovers.code">Item Handover Code</vs-th>
                <vs-th sort-key="canvas_item_requests.date">Loading Date</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty (UOM)</vs-th>
                <vs-th sort-key=""># Handovered</vs-th>
            </template>

            <template v-if="status === 'Handovered'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="personals.name">Sales</vs-th>
                <vs-th sort-key="warehouse.name">Warehouse</vs-th>
                <vs-th sort-key="canvas_item_requests.code">Item Request Code</vs-th>
                <vs-th sort-key="canvas_handovers.code">Item Handover Code</vs-th>
                <vs-th sort-key="canvas_item_requests.date">Loading Date</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty (UOM)</vs-th>
                <vs-th sort-key=""># Sales Confirmed</vs-th>
            </template>

            <template v-if="status === 'Complete'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="personals.name">Sales</vs-th>
                <vs-th sort-key="warehouse.name">Warehouse</vs-th>
                <vs-th sort-key="canvas_item_requests.code">Item Request Code</vs-th>
                <vs-th sort-key="canvas_handovers.code">Item Handover Code</vs-th>
                <vs-th sort-key="canvas_item_requests.date">Loading Date</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty (UOM)</vs-th>
                <vs-th sort-key=""># Sales Confirmed</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-button 
                                size="small" 
                                color="#6200e1" 
                                icon-pack="feather" 
                                icon="icon-shopping-cart" 
                                title="Handover"
                                v-if="status === 'Handover'" 
                                style="margin-right: 5px" 
                                @click="handleHandOver(tr.id)"
                            />
                            <vs-button
                                size="small"
                                color="#0f97d6"
                                icon-pack="feather"
                                icon="icon-printer"
                                title="Print Picking List"
                                v-if="status === 'Handover'"
                                style="margin-right: 5px"
                                @click="handlePrintPickingList(tr.id)"
                            />
                            <vs-button 
                                size="small" 
                                color="success" 
                                icon-pack="feather" 
                                icon="icon-check" 
                                title="Confirm"
                                v-if="status === 'Handover'" 
                                @click="handleConfirm(tr.id)" 
                                :disabled="tr.handover_status === '0'"
                            />
                            <vs-button 
                                size="small" 
                                color="warning" 
                                icon-pack="feather" 
                                icon="icon-eye"
                                v-if="status !== 'Handover'" 
                                title="Detail" 
                                style="margin-right: 5px" 
                                @click="handleDetail(tr.id)"
                            />
                            <!-- <vs-button 
                                size="small" 
                                color="danger" 
                                icon-pack="feather" 
                                icon="icon-alert-triangle"
                                v-if="status !== 'Handover'" 
                                title="Maintenance" 
                                style="margin-right: 5px" 
                                @click="handleMaintenance(tr.id)"
                            /> -->
                        </div>
                    </vs-td>
                    <vs-td v-if="status === 'Handover'">{{ tr.sales_name }}</vs-td>
                    <vs-td v-if="status === 'Handover'">{{ tr.warehouse }}</vs-td>
                    <vs-td v-if="status === 'Handover'">{{ tr.code }}</vs-td>
                    <vs-td v-if="status === 'Handover'">{{ tr.handover_code }}</vs-td>
                    <vs-td v-if="status === 'Handover'">{{ tr.date }}</vs-td>
                    <vs-td v-if="status === 'Handover'">{{ tr.total_sku }}</vs-td>
                    <vs-td v-if="status === 'Handover'">{{ tr.quantity_uom }}</vs-td>
                    <vs-td v-if="status === 'Handover'">{{ tr.qty_handovered }}</vs-td>

                    <vs-td v-if="status === 'Handovered'">{{ tr.sales_name }}</vs-td>
                    <vs-td v-if="status === 'Handovered'">{{ tr.warehouse }}</vs-td>
                    <vs-td v-if="status === 'Handovered'">{{ tr.code }}</vs-td>
                    <vs-td v-if="status === 'Handovered'">{{ tr.handover_code }}</vs-td>
                    <vs-td v-if="status === 'Handovered'">{{ tr.date }}</vs-td>
                    <vs-td v-if="status === 'Handovered'">{{ tr.qty_handovered }}</vs-td>
                    <vs-td v-if="status === 'Handovered'">{{ tr.quantity_uom }}</vs-td>
                    <vs-td v-if="status === 'Handovered'">{{ tr.qty_sales_confirmed }}</vs-td>

                    <vs-td v-if="status === 'Complete'">{{ tr.sales_name }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.warehouse }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.code }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.handover_code }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.date }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.total_sku }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.quantity_uom }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.qty_sales_confirmed }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage"/>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        territoryId: {
            type: Array,
        },
        warehouseId: {
            type: Array,
        },
        salesId: {
            type: Array,
        },
        status: {
            type: String,
            default: "Handover",
        },
    },
    data() {
        return {
            deleteId: null,
            action: null,
            table: this.tableDefaultState(),
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        getData() {
            let territoryIds = [];
            this.territoryId.forEach(val => {
                territoryIds.push(val.id)
            });

            let warehouseIds = [];
            this.warehouseId.forEach(val => {
                warehouseIds.push(val.id)
            });

            let salesIds = [];
            this.salesId.forEach(val => {
                salesIds.push(val.id)
            });

            let param = {
                length: this.table.length,
                page: this.table.page,
                search: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                territory_id: territoryIds,
                warehouse_id: warehouseIds,
                personal_id: salesIds,
                status: this.status,
            }

            this.$vs.loading();
            this.$http.post("/api/wms/v1/canvas/loading/data-table", param)
            .then((resp) => {
                if (resp.code == 200) {
                    this.table.total = resp.data.total_record;
                    this.table.totalPage = resp.data.total_page;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.total_record_per_page;
                    this.table.data = resp.data.records;
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                    this.$vs.dialog({
                        color: "danger",
                        title: "Something went wrong",
                        text: resp.message,
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
                this.$vs.dialog({
                    color: "danger",
                    title: "Something went wrong",
                    text: error,
                });
            });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleDetail(id) {
            if (this.status === 'Handovered') {
                this.$router.push({
                    name: "canvas.handover-detail-handovered",
                    params: { id: id },
                });
            } else if (this.status === 'Complete') {
                this.$router.push({
                    name: "canvas.handover-detail-complete",
                    params: { id: id },
                });
            }
        },
        handlePrint(id) {
            const routeData = this.$router.resolve({
                name: "canvas.handover-actual-print",
                params: { id: id },
            });
            window.open(routeData.href, "_blank");
        },
        handleEdit(id) {
            this.$router.push({
                name: "canvas.handover-picking-items",
                params: { id: id },
            });
        },
        handleConfirm(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to confirm this data to sales?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/canvas/loading/confirm-handover-to-sales/" + this.deleteId)
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: "The data was successfully confirmed to sales",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        }

                        this.$vs.loading.close();
                        this.getData();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                }
            })
        },
        handleHandOver(id) {
            this.$router.push({
                name: "canvas.handover-detail-handover",
                params: { id: id },
            });
        },
        handlePrintPickingList(id) {
            const routeData = this.$router.resolve({
                name: "canvas.handover-print-picking-list",
                params: { id: id },
            });
            window.open(routeData.href, "_blank");
        },
        handleMaintenance(id) {
            this.$http.post("/api/wms/v1/canvas/loading/aqua-dulu/"+id)
            .then(resp => {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            })
            .catch(error => {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: error,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            });
        },
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
}
</script>