<template>
    <v-select 
        multiple
        :filterable="true" 
        :options="options" 
        v-model="selected" 
        :placeholder="placeholder"
        @input="val => { onChange(val); }"
        @search="(search, loading) => { onInput(search, loading); }"
    />
</template>

<script>
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        draw: {
            type: Number,
        },
        placeholder: {
            type: String
        },
        value: {
            type: Object
        },
        warehouseId: {
            type: Array,
        },
    },
    data() {
        return {
            baseUrl: "/api/wms/v1/canvas/loading/salesman-filter",
            options: [],
            selected: []
        };
    },
    methods: {
        get() {
            let warehouseIds = [];
            this.warehouseId.forEach(val => {
                warehouseIds.push(val.id)
            });

            let param = {
                search: "",
                warehouse_ids: warehouseIds,
            }

            this.$http.post(this.baseUrl, param)
            .then(resp => {
                if (resp.code == 200) {
                    this.options = []
                    if (resp.data.records) {
                        this.options.push({ id: 0, label: 'All' });
                        this.options.push(...resp.data.records);
                    }

                    // ...
                    if (!this.selected.length) {
                        this.selected.push({ id: 0, label: 'All' });
                    } else if (this.selected.length > 0 && this.selected[0].id != 0) {
                        if (resp.data.records) {
                            let respSalesIDs = resp.data.records.map(record => record.id || 0);
                            this.selected = this.selected.filter(item => respSalesIDs.includes(item.id));

                            if (!this.selected.length) {
                                this.selected.push({ id: 0, label: 'All' });
                            }
                        }
                    }
                } else {
                    this.options = []
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        onChange(val) {
            if (!val.length) {
                this.selected[0] = this.options[0]
            } else if (val.length > 1) {
                let lastItem = val.slice(-1)[0];
                if (lastItem.id == 0) {
                    this.selected = [];
                    this.selected[0] = this.options[0]
                } else {
                    this.selected = this.selected.filter(item => item.id !== 0);
                }
            }
        },
        onInput(search, loading) {
            if (search != "") {
                loading(true);
                this.onSearch(search, loading);
            } else {
                loading(true);
                this.onSearch("", loading);
            }
        },
        onSearch(search, loading) {
            let warehouseIds = [];
            this.warehouseId.forEach(val => {
                warehouseIds.push(val.id)
            });

            let param = {
                search: search,
                warehouse_ids: warehouseIds,
            }

            this.$http.post(this.baseUrl, param)
            .then(resp => {
                if (resp.code == 200) {
                    this.options = [];
                    if (resp.data.records) {
                        this.options.push({ id: 0, label: 'All' });
                        this.options.push(...resp.data.records);
                    }
                    loading(false);
                } else {
                    this.options = []
                    loading(false);
                }
            })
            .catch(error => {
                console.log(error)
            });
        }
    },
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        },
        value(v) {
            if (v) {
                this.selected = v
            }
        },
        warehouseId(v) {
            if (v) {
                this.get();
            }
        }
    },
    mounted() {
        this.get();
    }
};
</script>